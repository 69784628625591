import { scroller } from 'react-scroll'
import { AnalyticsEventName, useAnalytics } from '#lib/analytics'
import { StyledScrollButton } from './ScrollButton.style'

const scrollTo = (target: string) => {
  scroller.scrollTo(target, {
    duration: 500,
    smooth: true,
  })
}

export const ScrollButton = ({ target }: { target: string }) => {
  const analytics = useAnalytics()

  return (
    <StyledScrollButton
      name="foo"
      onClick={() => {
        scrollTo(target)
        analytics?.track(AnalyticsEventName.ButtonClick, { button_name: 'Scroll button' })
      }}
    >
      {/* TODO: we should really be using `<Image />` from `next/image` */}
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src="images/icons/chevron-down-icon.svg" alt="Down arrow" />
    </StyledScrollButton>
  )
}
