import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'

export const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  top: ${({ theme }) => theme.spacing.regular};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.actionPrimary};
  border-radius: 99rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: ${({ theme }) => theme.maxWidth};
  }
`

export const MessageMarkdown = styled(ReactMarkdown)`
  flex: 1;
  text-align: center;
  font-size: 1.125rem;
  margin: 0.75rem 1.5rem;

  a {
    color: ${({ theme }) => theme.colors.typePrimary};
    font-weight: bold;
    text-decoration: underline;
  }
`

export const DismissButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  margin-right: 0.4rem;
  border: 0;
  border-radius: 99rem;
  background-color: ${({ theme }) => theme.colors.bgPrimary};

  :hover {
    background-color: ${({ theme }) => theme.colors.bgSecondary};
  }
`

export const DismissIcon = styled.img`
  width: 0.8rem;
`
