import { keyframes } from 'styled-components'
import styled from 'styled-components'

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-15px);
  }
`

export const StyledScrollButton = styled.button`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.regular};
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 3.125rem;
  min-height: 3.125rem;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.architecture8};
  animation-name: ${bounceAnimation};
  animation-duration: 4s;
  animation-iteration-count: 1;

  background-color: ${({ theme }) => theme.colors.actionPrimary};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.bgDark};
    > img {
      /* White */
      filter: brightness(0) invert(1);
    }
  }

  > img {
    width: 1.5rem;
    /* Looks visually balanced off-center */
    transform: translateY(5%);
  }

  @media (min-height: 475px) {
    bottom: ${({ theme }) => theme.spacing.medium};
  }

  @media (min-height: ${({ theme }) => theme.breakpoints.tablet}) {
    bottom: ${({ theme }) => `calc(${theme.spacing.medium} * 1.5)`};
  }
`
