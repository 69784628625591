import { Fade } from 'react-awesome-reveal'
import { StrapiImage } from '#cmsApi/types'
import { CallToActionLink } from '#components/ButtonsAndLinks'
import { Item, Props as ItemProps } from './Item'
import {
  ContentContainer,
  Heading,
  SectionContainer,
  SemiCircleImageContainer,
} from './SemiCircleFeaturesSection.style'

type Props = {
  heading: string
  items: ItemProps[]
  callToAction: string
  url: string
  image: StrapiImage
  pageName: string
}

export const SemiCircleFeaturesSection = ({
  heading,
  items,
  callToAction,
  url,
  image,
  pageName,
}: Props) => {
  const semiCircleImage = image.data?.attributes

  return (
    <SectionContainer>
      <ContentContainer>
        <Fade>
          <Heading>{heading}</Heading>
        </Fade>
        {items.map(({ icon, label, description }) => (
          <Fade key={label}>
            <Item {...{ icon, label, description }} />
          </Fade>
        ))}
        <CallToActionLink
          href={url}
          trackingName={`${pageName} - Semi circle features section - ${callToAction}`}
        >
          {callToAction}
        </CallToActionLink>
      </ContentContainer>
      <SemiCircleImageContainer>
        {/* TODO: we should really be using `<Image />` from `next/image` */}
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={semiCircleImage?.url} alt={semiCircleImage?.alternativeText} />
      </SemiCircleImageContainer>
    </SectionContainer>
  )
}
