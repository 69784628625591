import styled from 'styled-components'

export const CompanyLogos = styled.section`
  padding: 12rem 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 5rem 0;
  }
`

export const InnerContainer = styled.ul`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.medium};
  margin: 0 ${({ theme }) => theme.spacing.regular};
  overflow-x: auto;
  padding-inline-start: 0;

  ::-webkit-scrollbar {
    border-radius: 5px;
    height: 10px;
    background: ${({ theme }) => theme.colors.bgPrimary};
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${({ theme }) => theme.colors.bgDark};
  }
`

export const ImageContainer = styled.li`
  display: flex;
  justify-content: center;
  min-width: 10rem;
  list-style: none;
  margin: auto;
`

export const CompanyLogo = styled.img`
  height: 6rem;
  object-fit: contain;
`
